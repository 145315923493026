import fm from 'format-message';
import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
// import HeartIcon from 'components/icons/HeartIcon';
import Paragraph from 'components/text/Paragraph';

export const Wrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'end',
    flexDirection: 'column',
    padding: '10px 10px 10px 0',
});

// @todo: add onclick for adding to wishlist
const OrderCardEnd = ({ $style, quantity = 1, showFavorite = true }) =>
    quantity ? (
        <Wrapper $style={{ $style, justifyContent: showFavorite ? 'space-between' : 'end' }}>
            {/* {showFavorite ? <HeartIcon /> : null} */}
            <Paragraph
                $style={{ color: 'var(--color-text-subtle)', whiteSpace: 'nowrap' }}
                fontKeys="Primary/12_100_-1"
            >
                {fm('Quantity')}: {quantity}
            </Paragraph>
        </Wrapper>
    ) : null;

OrderCardEnd.propTypes = {
    $style: PropTypes.object,
    quantity: PropTypes.number.isRequired,
    showFavorite: PropTypes.bool,
};
export default OrderCardEnd;
