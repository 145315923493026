import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const BasketIcon = ({ $style, color = 'currentColor', height = '24px', width = '24px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox="0 0 16 16" {...rest}>
            <path stroke={color} d="M7 3.5h7.5v12h-13v-12H4" />
            <path stroke={color} d="M5.5 4V3C5.5 1.61929 6.61929.5 8 .5s2.5 1.11929 2.5 2.5v1" />
        </SVG>
    );
};

BasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default BasketIcon;
