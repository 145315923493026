// @TODO FontWeights should depend on the fonts
export const fontWeights = [200, 300, 400, 500, 600, 400, 800, 900];

// @TODO Style should depends on the font
// https://stackoverflow.com/questions/56949513/typescript-type-of-a-property-dependent-on-another-property-within-the-same-obj
export interface TextStyle {
    fontFamily: string;
    fontSize: number;
    fontStyle: string;
    fontWeight?: string | number;
    letterSpacing: string;
    lineHeight: string;
    textDecoration?: string;
    textTransform: string;
}

const textStyles: Record<string, TextStyle> = {
    // Primary font styles
    'Primary/80_90_-4': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 80,
        letterSpacing: '-0.4rem',
        lineHeight: '90%',
        textTransform: 'none',
    },
    'Primary/64_100_-4': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 64,
        letterSpacing: '-0.4rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/48_100_-3': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 48,
        letterSpacing: '-0.3rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/40_110_-2': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 40,
        letterSpacing: '-0.2rem',
        lineHeight: '110%',
        textTransform: 'none',
    },
    'Primary/32_100_-2': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 32,
        letterSpacing: '-0.2rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/24_120_-1': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 24,
        letterSpacing: '-0.1rem',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/22_120_-05': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 22,
        letterSpacing: '-0.05rem',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/18_100_-05': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-0.05rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/18_100_-3': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/18_120_-05': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 18,
        letterSpacing: '-0.05rem',
        lineHeight: '120%',
        textTransform: 'none',
    },
    'Primary/16_100_-05': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.05rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/16_140_-05': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.05rem',
        lineHeight: '140%',
        textTransform: 'none',
    },
    'Primary/16_100_-05_500': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.05rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/16_100_-03': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-0.03rem',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/16_100_-3_500': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 16,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/14_100_-3': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/14_140_-2': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-2%',
        lineHeight: '140%',
        textTransform: 'none',
    },
    'Primary/14_100_-3_500': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/14_100_-3_LT': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '-3%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/14_100_-2_500': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: 'normal',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/12_100_-1': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-1%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/12_100_-1_500': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: 12,
        letterSpacing: '-1%',
        lineHeight: '100%',
        textTransform: 'none',
    },
    'Primary/10_70': {
        fontFamily: 'Scto Grotesk A',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 10,
        letterSpacing: 'normal',
        lineHeight: '70%',
        textTransform: 'none',
    },
};

export default textStyles;
