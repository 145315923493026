import PropTypes from 'prop-types';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import useReInitWidgets from '@activebrands/core-web/libs/lipscore/hooks/useReInitWidgets';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { useLocation } from '@reach/router';
import ArticleCard from 'components/ArticleCard';
import FeatureCard from 'components/FeatureCard';
import ProductCard from 'components/product/ProductCard';
import PromotionalSlider from 'components/slider/PromotionalSlider';

const PromotionSlider = ({
    $style = {},
    $headerStyle = {},
    button = {},
    heading,
    headingFontKeys,
    headingType,
    id,
    imageSettings,
    items = [],
    slidesPerView,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();
    const location = useLocation();
    useReInitWidgets(location.href);

    const displaySlider = items.length > 2;

    return (
        <div className={css($style)}>
            <PromotionalSlider
                isDraggable
                isInfinite={false}
                $paginationStyle={{
                    display: displaySlider ? ['none', null, null, null, 'initial'] : 'none',
                }}
                $headerStyle={$headerStyle}
                button={button}
                heading={heading}
                headingFontKeys={headingFontKeys}
                headingType={headingType}
                id={id}
                slidesPerView={type === 'article' ? [1.3, null, 1.4, null, null, 2] : slidesPerView}
                snapAlign="none"
                spaceBetween="8px"
                tag={tag}
                tagFontKeys="Primary/14_100_-3"
                text={text}
                type={type}
                $style={{ display: 'flex', flexDirection: 'column' }}
            >
                {items.map((item, i) => {
                    if (type === 'article') {
                        return (
                            <ArticleCard
                                {...item}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                                trackingList={TrackingLists.PRODUCT_GALLERY}
                            />
                        );
                    }

                    if (type === 'feature') {
                        return <FeatureCard {...item} imageSettings={imageSettings} key={item.id} loading="lazy" />;
                    }

                    if (type === 'product') {
                        return (
                            <ProductCard
                                {...item}
                                $style={{ padding: '0' }}
                                media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                key={item.id}
                                imageSettings={imageSettings}
                                loading="lazy"
                                position={i}
                                trackingList={TrackingLists.PRODUCT_GALLERY}
                            />
                        );
                    }

                    return null;
                })}
            </PromotionalSlider>
        </div>
    );
};

PromotionSlider.propTypes = {
    $style: PropTypes.object,
    $headerStyle: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    items: PropTypes.array,
    slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    tag: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(['product', 'article']),
};

export default PromotionSlider;
