import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import NewsletterWidget from '@activebrands/core-web/components/Newsletter/Widget';
import NewsletterEvents from '@activebrands/core-web/components/Newsletter/Events';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';

const Newsletter = ({
    $style = {},
    background,
    backgroundLoading = 'lazy',
    backgroundOverlay,
    contentLayout = 'default',
    heading = '',
    headingType = 'h2',
    isPopup = false,
    newsletter = {},
    paragraph = '',
    tag = '',
    textColor,
}) => {
    const [css] = useStyletron();

    const layoutVertical = contentLayout === 'vertical';
    const layoutHorizontal = contentLayout === 'horizontal';

    return (
        <div
            className={css({
                width: '100%',
                height: '100%',
                minHeigt: '400px',
                padding: isPopup ? '36px 12px 12px' : ['12px', null, null, null, '16px'],
                position: 'relative',
                display: 'grid',
                overflow: 'hidden',
                gridTemplateColumns: 'repeat(12, 1fr)',
                flexDirection: layoutVertical
                    ? 'column'
                    : layoutHorizontal
                        ? 'row'
                        : ['column', null, null, null, 'row'],
                ...$style,
            })}
        >
            <BackgroundDynamic
                background={background}
                backgroundOverlay={backgroundOverlay}
                loading={backgroundLoading}
            />
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '24px',
                    gridColumn: layoutVertical
                        ? '1 / span 12'
                        : layoutHorizontal
                            ? '1 / span 4'
                            : ['1 / span 12', null, null, null, '1 / span 4'],
                    justifyContent: layoutVertical
                        ? 'flex-start'
                        : layoutVertical
                            ? 'center'
                            : ['flex-start', null, null, null, 'center'],
                    ...getTextColorStyles(textColor),
                })}
            >
                {tag && (
                    <Tag
                        fontKeys="Primary/14_100_-3"
                        $style={{
                            marginBottom: ['16px', null, null, null, '24px'],
                            width: 'fit-content',
                            border: '0',
                            padding: '0',
                            '::after': {
                                content: 'none',
                            },
                        }}
                    >
                        {tag}
                    </Tag>
                )}
                <div>
                    {heading && (
                        <Heading
                            $style={{ marginBottom: '16px' }}
                            as={headingType}
                            fontKeys={
                                isPopup
                                    ? ['Primary/24_120_-1', null, null, null, 'Primary/32_100_-2']
                                    : ['Primary/24_120_-1', null, null, null, 'Primary/48_100_-3']
                            }
                        >
                            {heading}
                        </Heading>
                    )}
                    {paragraph && (
                        <div className={css(textStyles['Primary/16_140_-05'])}>
                            <RichText data={paragraph} />
                        </div>
                    )}
                </div>
            </div>
            <div
                className={css({
                    display: 'flex',
                    justifyContent: layoutVertical
                        ? 'center'
                        : layoutHorizontal
                            ? 'flex-start'
                            : ['center', null, null, null, 'flex-start'],
                    gridColumn: layoutVertical
                        ? '1 / span 12'
                        : layoutHorizontal
                            ? '7 / span 6'
                            : ['1 / span 12', null, null, null, '7 / span 6'],
                })}
            >
                <NewsletterWidget {...newsletter} />
                <NewsletterEvents />
            </div>
        </div>
    );
};

Newsletter.propTypes = {
    $style: PropTypes.object,
    background: PropTypes.object,
    backgroundLoading: PropTypes.string,
    backgroundOverlay: PropTypes.object,
    contentLayout: PropTypes.oneOf(['default', 'horizontal', 'vertical']),
    heading: PropTypes.string,
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    isPopup: PropTypes.bool,
    newsletter: PropTypes.object,
    paragraph: PropTypes.string,
    tag: PropTypes.string,
    textColor: PropTypes.string,
};

export default Newsletter;
