import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import HeartIcon from 'components/icons/HeartIcon';
import useSiteMetaQuery from 'queries/useSiteMetaQuery';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import { impressionDebounce } from '@activebrands/core-web/components/tracking/impressionDebounce';
import useIntersectionObserver from '@activebrands/core-web/hooks/useIntersectionObserver';
import Events from '@activebrands/core-web/libs/Events';
import { ProductEvents } from '@activebrands/core-web/libs/Events/types';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import LipScoreProductWidget from '@activebrands/core-web/libs/lipscore/productReviewWidgets/LipScoreProductWidget';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { addLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import getCampaignPrice from '@activebrands/core-web/utils/product/get-campaign-price';
import { formatCentraFeedId } from '@activebrands/core-web/utils/tracking';
import Badges from 'components/Badges';
import Quickshop from 'components/product/Quickshop/index';
import GalleryCardBottomSection from './GalleryCardBottomSection';
import GridCardBottomSection from './GridCardBottomSection';
import SingleViewCardBottomSection from './SingleViewCardBottomSection';
import WishlistCardBottomSection from './WishlistCardBottomSection';
import mediaBreakpoint from '@activebrands/core-web/config/media';

/**
 * INFO:
 *
 * We no longer reinitialize lipscore for each product card.
 * There fore make sure to the the "useReInitWidgets" in the parent component that
 * renders all product cards if the lipscore rating (stars) doesn't show.
 *
 * It can be useful to pass the location.href as a dependency to useReInitWidgets
 * to make sure the lipscore reinitializes when the site href changes.
 */

const Wrapper = styled('article', {
    width: '100%',
    fontFamily: 'sans-serif',
    position: 'relative',
    overflow: 'hidden',
    padding: ['0 4px', null, null, null, '0 8px'],
});

const TopSection = styled('div', {
    display: 'flex',
    width: '100%',
    height: '20px',
    color: 'var(--color-text-badge)',
});

const ReviewsWrapper = styled('div', {
    position: 'absolute',
    top: ['4px', null, null, null, '8px'],
    right: ['4px', null, null, null, '8px'],
    zIndex: 1,
    transform: 'scale(0.6)',
    transformOrigin: '100% 50%',

    [mediaBreakpoint.min['desktop.sm']]: {
        transform: 'scale(0.8)',
    },
});

const ProductMedia = styled('div', {
    position: 'relative',
    overflow: 'hidden',
});

const bottomSections = {
    grid: GridCardBottomSection,
    'single-view': SingleViewCardBottomSection,
    gallery: GalleryCardBottomSection,
    wishlist: WishlistCardBottomSection,
};

const ProductCard = ({
    $style,
    badges = [],
    categories = [],
    color = '',
    id,
    media = [],
    imageSettings = { width: [200, 400, 600, 800], sizes: ['100vw', '50vw', '33vw'] },
    loading = 'eager',
    name,
    objectID,
    position,
    parentSku,
    price: defaultPrice,
    quickShop = true,
    recommendationId,
    relatedVariations = [],
    searchMeta = {},
    sku,
    style,
    trackingList,
    uri,
    variations = [],
    layout = 'grid',
    gridRow = 1,
    // videoSrc = '',  // Prepping for video support
}) => {
    const [css] = useStyletron();
    const { siteURL } = useSiteMetaQuery();

    // If there is a active campaign, use that price instead
    const campaignPricelist = useSelector(state => state.campaignSite.campaignPricelist);
    const campaignPrice = getCampaignPrice(id, campaignPricelist);
    const price = campaignPrice || defaultPrice;
    const mediaObject = media?.[0] || {};

    const BottomSection = bottomSections[layout];
    const currencyCode = useSelector(state => state.application.shopConfig.currency);

    const centraFeedId = variations.length ? formatCentraFeedId(variations[0]) : undefined;

    const trackHandler = () => {
        Events.trigger(ProductEvents.CLICK, {
            currencyCode,
            product: {
                categories,
                id: centraFeedId,
                sku: variations.length ? variations[0].sku : sku,
                name,
                position,
                price,
                trackingList,
            },
        });
        if (Object.keys(searchMeta).length > 0) {
            Events.trigger(SearchEvents.CLICK, {
                ...searchMeta,
                eventName: trackingList,
                positions: [position + 1],
                objectIDs: [objectID],
            });
        }
    };

    const { shopConfig } = useSelector(state => state.application);
    const [observe, unobserve] = useIntersectionObserver(entry => {
        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            impressionDebounce(
                {
                    categories,
                    id: centraFeedId,
                    sku: variations.length ? variations[0].sku : sku,
                    name,
                    position,
                    price,
                    trackingList,
                },
                shopConfig.currency,
                gridRow
            );
            unobserve();
        }
    });

    const addedToBasketData = {
        color,
        media: mediaObject,
        link: uri,
        name,
        priceWithCurrency: price?.priceWithCurrency,
        salePriceWithCurrency: price?.salePriceWithCurrency,
    };

    const addedToBasketAlgoliaData = {
        ...searchMeta,
        eventName: trackingList,
        objectIDs: [objectID],
    };

    const isTypeValueProduct = mediaObject.typeValue === 'Product';
    const typeValueProductStyles = {
        padding: 'var(--padding-image-productCard)',
        backgroundColor: 'var(--color-bg-image)',
        objectFit: 'contain',
    };

    return (
        <Wrapper
            $style={$style}
            data-product_id={recommendationId && id}
            data-recommendation_id={recommendationId}
            ref={observe}
            style={style}
            onClick={trackHandler}
        >
            <TopSection>
                {!!badges.length && (
                    <Badges
                        $style={{
                            width: ['70%', null, null, null, null, '50%'],
                            overflow: 'hidden',
                        }}
                        items={badges}
                        fontKeys="Primary/12_100_-1"
                    />
                )}
            </TopSection>
            <ProductMedia>
                <ReviewsWrapper>
                    <LipScoreProductWidget
                        readonly
                        as="span"
                        className="lipscore-rating-small"
                        key={sku}
                        productCategory={categories?.[0]?.name}
                        productCurrency={price?.currency}
                        productDetailPage={`${siteURL}${addLocalePrefix(uri)}`}
                        productID={parentSku}
                        productImage={media?.[0]?.url}
                        productName={name}
                        productSellingPrice={price?.salePrice}
                    />
                </ReviewsWrapper>
                {/* TODO: Add addtowishlist functionality */}
                {/* <HeartIcon $style={{ position: 'absolute', bottom: '0', left: '0', padding: '12px' zIndex: 1 }} /> */}
                {media.length > 0 ? (
                    <Link key={mediaObject.url || mediaObject.video} to={uri}>
                        <div
                            className={css({
                                aspectRatio: 'var(--ratio-vertical-secondary)',
                                backgroundColor: 'var(--color-bg-image)',
                            })}
                        >
                            {mediaObject.url && (
                                <Image
                                    $style={isTypeValueProduct ? typeValueProductStyles : {}}
                                    alt={name}
                                    loading={loading}
                                    sizes={imageSettings.sizes}
                                    src={{
                                        url: mediaObject.url,
                                        width: imageSettings.width,
                                    }}
                                />
                            )}
                        </div>
                    </Link>
                ) : (
                    <Link to={uri}>
                        <div
                            className={css({
                                aspectRatio: 'var(--ratio-vertical-secondary)',
                                backgroundColor: 'var(--color-bg-image)',
                            })}
                        />
                    </Link>
                )}
                {ecommerceEnabled && quickShop && (
                    <Quickshop
                        activePrice={price?.salePriceWithCurrency}
                        addedToBasketAlgoliaData={addedToBasketAlgoliaData}
                        addedToBasketData={addedToBasketData}
                        color={color}
                        id={id}
                        name={name}
                        originalPrice={price?.priceWithCurrency}
                        uri={uri}
                        variations={variations}
                    />
                )}
            </ProductMedia>
            <BottomSection
                currentVariationId={id}
                name={name}
                price={price}
                relatedVariations={relatedVariations}
                uri={uri}
            />
        </Wrapper>
    );
};

ProductCard.propTypes = {
    $style: PropTypes.object,
    badges: PropTypes.array,
    categories: PropTypes.array,
    color: PropTypes.string,
    gridRow: PropTypes.number,
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    layout: PropTypes.string,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    media: PropTypes.arrayOf(
        PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.oneOf(['image', 'video']),
            format: PropTypes.string,
        })
    ),
    name: PropTypes.string,
    objectID: PropTypes.string,
    parentSku: PropTypes.string,
    position: PropTypes.number,
    price: PropTypes.object,
    quickShop: PropTypes.bool,
    recommendationId: PropTypes.string,
    relatedVariations: PropTypes.array,
    searchMeta: PropTypes.object,
    sku: PropTypes.string,
    style: PropTypes.object,
    trackingList: PropTypes.string,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default ProductCard;
