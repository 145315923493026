import SVG from '@activebrands/core-web/components/SVG';

interface ArrowIconProps {
    $style?: any;
    color?: string;
    viewBox?: string;
    size?: string | Array<string | null>;
}

const ArrowIcon = ({
    $style = {},
    color = 'currentColor',
    size = '24px',
    viewBox = '0 0 12 12',
    ...rest
}: ArrowIconProps) => {
    const style = { width: size, height: size, ...$style };

    return (
        <SVG $style={style} fill="none" viewBox={viewBox} {...rest}>
            <path stroke={color} d="M4.5 11.5 11 6 4.5.5M1 6h9.5" />
        </SVG>
    );
};

export default ArrowIcon;
