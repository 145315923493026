import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import Button from 'components/buttons/Button';
import Checkbox from 'components/inputs/CheckboxInput';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', ({ $isOpen }) => ({
    position: 'absolute',
    top: 'var(--height-header)',
    bottom: '8px',
    transform: $isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-120%, 0, 0)',
    backgroundColor: 'var(--color-bg-navigation)',
    paddingTop: '16px',
    width: '100%',
    zIndex: 1,
    transition: 'transform var(--transition-fast)',
    overflow: 'auto',
    paddingBottom: '124px', // Prevents the last option to be hidden behind the toggle element

    // Hide scrollbar in safari and chrome
    '::-webkit-scrollbar': {
        display: 'none',
    },

    // Hide scrollbar in firefox
    scrollbarWidth: 'none',

    [media.min['desktop.sm']]: {
        top: '24px',
    },
}));

const SiteSelector = ({ $style, alternateHrefs = [], isSiteSelectorOpen }) => {
    const [css] = useStyletron();

    const {
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorNavigationHeading,
        siteSelectorNavigationHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
    } = useSiteSelectorQuery();

    const currentSiteId = useSelector(state => state.application.aliasId);
    const [isAlwaysRedirected, setIsAlwaysRedirected] = useState(true);

    const handleChange = () => {
        setIsAlwaysRedirected(!isAlwaysRedirected);
    };

    return (
        <Wrapper $isOpen={isSiteSelectorOpen} $style={$style}>
            {siteSelectorLinks.length && (
                <div className={css({ display: 'flex', flexDirection: 'column', gap: '8px' })}>
                    {siteSelectorNavigationHeading && (
                        <Paragraph
                            $style={{ padding: '0 12px' }}
                            as={siteSelectorNavigationHeadingType}
                            fontKeys="Primary/24_120_-1"
                        >
                            {siteSelectorNavigationHeading}
                        </Paragraph>
                    )}
                    {siteSelectorParagraph?.content?.length && (
                        <div
                            className={css({
                                ...responsiveTextStyles(['Primary/14_140_-2', null, null, null, 'Primary/16_140_-05']),
                                padding: '0 12px',
                                marginBottom: '8px',
                            })}
                        >
                            <RichText data={siteSelectorParagraph} />
                        </div>
                    )}
                    {siteSelectorRedirectLabel && (
                        <div
                            className={css({
                                ...textStyles['Primary/12_100_-1_500'],
                                marginBottom: '16px',
                                paddingLeft: '12px',
                            })}
                        >
                            <Checkbox
                                checked={isAlwaysRedirected}
                                label={siteSelectorRedirectLabel}
                                onChange={handleChange}
                            />
                        </div>
                    )}
                    <div className={css({ display: 'flex', flexDirection: 'column' })}>
                        {siteSelectorLinks.map((site, index) => (
                            <Button
                                $style={{
                                    width: '100%',
                                    padding: '12px',
                                    borderBottom: '1px solid var(--color-border-navigation)',
                                    backgroundColor:
                                        site.id === currentSiteId ? 'var(--color-bg-button-navigation-active)' : null,
                                }}
                                as="a"
                                href={alternateHrefs.find(href => href.aliasId === Number(site.id))?.uri || site.path}
                                key={`${site.countryCode}-${index}`}
                                onClick={() => (!!isAlwaysRedirected ? setSiteSelectorCookie(site.path) : null)}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        alignSelf: 'center',
                                    })}
                                >
                                    {site.icon && (
                                        <div
                                            className={css({
                                                marginRight: '8px',
                                                width: '24px',
                                                height: '16px',
                                            })}
                                            src={site.icon}
                                        >
                                            <Image sizes={['24px']} src={{ url: site?.icon, width: [24, 48, 72] }} />
                                        </div>
                                    )}
                                    <Paragraph className={css({ textAlign: 'left' })} fontKeys="Primary/16_100_-05">
                                        {site.label}
                                    </Paragraph>
                                </div>
                            </Button>
                        ))}
                    </div>
                </div>
            )}
            {siteSelectorExternalLinks.length ? (
                <div className={css({ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '64px' })}>
                    {siteSelectorExternalHeading && (
                        <Paragraph
                            $style={{ padding: '0 12px' }}
                            as={siteSelectorExternalHeadingType}
                            fontKeys="Primary/24_120_-1"
                        >
                            {siteSelectorExternalHeading}
                        </Paragraph>
                    )}
                    {siteSelectorExternalParagraph?.content?.length && (
                        <div
                            className={css({
                                ...responsiveTextStyles(['Primary/14_140_-2', null, null, null, 'Primary/16_140_-05']),
                                padding: '0 12px',
                                marginBottom: '8px',
                            })}
                        >
                            <RichText data={siteSelectorExternalParagraph} />
                        </div>
                    )}
                    <div className={css({ display: 'flex', flexDirection: 'column' })}>
                        {siteSelectorExternalLinks.map((site, index) => (
                            <Button
                                $style={{
                                    width: '100%',
                                    padding: '12px',
                                    borderBottom: '1px solid var(--color-border-navigation)',
                                    backgroundColor:
                                        site.id === currentSiteId ? 'var(--color-bg-button-navigation-active)' : null,
                                }}
                                as="a"
                                href={site.path}
                                key={`${site.countryCode}-${index}`}
                                onClick={() => (!!isAlwaysRedirected ? setSiteSelectorCookie(site.path) : null)}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        alignSelf: 'center',
                                    })}
                                >
                                    {site.icon && (
                                        <div
                                            className={css({
                                                marginRight: '8px',
                                                width: '24px',
                                                height: '16px',
                                            })}
                                            src={site.icon}
                                        >
                                            <Image sizes={['24px']} src={{ url: site?.icon, width: [24, 48, 72] }} />
                                        </div>
                                    )}
                                    <Paragraph className={css({ textAlign: 'left' })} fontKeys="Primary/16_100_-05">
                                        {site.label}
                                    </Paragraph>
                                </div>
                            </Button>
                        ))}
                    </div>
                </div>
            ) : null}
        </Wrapper>
    );
};

SiteSelector.propTypes = {
    $style: PropTypes.object,
    alternateHrefs: PropTypes.arrayOf(
        PropTypes.exact({
            aliasId: PropTypes.number,
            app: PropTypes.string,
            appId: PropTypes.number,
            hrefLang: PropTypes.string,
            locale: PropTypes.string,
            uri: PropTypes.string,
        })
    ),
    isSiteSelectorOpen: PropTypes.bool,
};

export default SiteSelector;
