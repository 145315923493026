import { styled } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {});

interface LipScoreProductWidgetProps {
    $style?: any;
    as: string;
    brandName: string;
    className: string;
    handleOnClick: () => void;
    id: string;
    pageSize: string;
    productCategory: string;
    productCurrency: string;
    productDescription: string;
    productDetailPage: string;
    productGTIN: string;
    productID: string;
    productImage: string;
    productName: string;
    productSellingPrice: string;
    productStock: string;
    variantID: string;
    variantName: string;
}

/**
 * LipScoreProductWidget component displays a product rating widget from LipScore.
 *
 * @param {LipScoreProductWidgetProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 *
 * @see {@link https://members.lipscore.com/widgets}
 */

const LipScoreProductWidget = ({
    $style = {},
    as = 'div',
    brandName = '',
    className = '',
    handleOnClick = () => {},
    id = '',
    pageSize = '',
    productCategory = '',
    productCurrency = '',
    productDescription = '',
    productDetailPage = '',
    productGTIN = '',
    productID = '',
    productImage = '',
    productName = '',
    productSellingPrice = '',
    productStock = '',
    variantID = '',
    variantName = '',
}: LipScoreProductWidgetProps) => {
    return (
        <Wrapper
            $as={as}
            $style={$style}
            className={className}
            data-ls-availability={productStock} // Items_in_stock-used_for_SEO
            data-ls-brand={brandName} // BrandName
            data-ls-category={productCategory} // Product_category-used_for_SEO
            data-ls-description={productDescription} // Product_description-used_for_SEO
            data-ls-gtin={productGTIN} // Product_GTIN-used_for_SEO
            data-ls-image-url={productImage} // URL_to_product_image
            data-ls-page-size={pageSize} // Number_of_reviews_that_are_displayed_per_page
            data-ls-price-currency={productCurrency} // Price_currency-used_for_SEO
            data-ls-price={productSellingPrice} // Selling_price-used_for_SEO
            data-ls-product-id={productID} // ProductID
            data-ls-product-name={productName} // ProductName
            data-ls-product-url={productDetailPage} // URL_to_product_details_page
            data-ls-variant-id={variantID} // VariantID
            data-ls-variant-name={variantName} // VariantName
            id={id}
            onClick={() => handleOnClick()}
        />
    );
};

export default LipScoreProductWidget;
