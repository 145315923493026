import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const CloseIcon = ({ $style = {}, color = 'currentColor', viewBox = '0 0 16 16', size = '24px', ...rest }) => {
    const style = { ...$style, width: size, height: size };
    return (
        <SVG $style={style} viewBox={viewBox} fill="none" {...rest}>
            <path d="M2 14L14 2" stroke={color} />
            <path d="M2 2L14 14" stroke={color} />
        </SVG>
    );
};

CloseIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default CloseIcon;
