import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const SearchIcon = ({ $style = {}, color = 'currentColor', height = '16px', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} viewBox="0 0 16 16" fill="none" {...rest}>
            <circle cx="6.49624" cy="6.49624" r="5.99624" stroke={color} />
            <path d="M12 12L15.1574 15.1579" stroke={color} />
        </SVG>
    );
};

SearchIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default SearchIcon;
