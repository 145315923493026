/* eslint-disable no-useless-escape */

import {
    containNumbersRegExp,
    isValidEmailRegExp,
    isValidExtendedLatinRegExp,
    isValidNameRegExp,
    isValidZipCodeRegExp,
    numbersOnlyAfterAtRegExp,
    numbersOnlyBeforeAtRegExp,
    unAllowedStreetNameCharsRegExp,
} from '@activebrands/core-web/utils/create-regExp';

export const addressRequiredFields = ['email', 'firstName', 'lastName', 'address1', 'city', 'zip', 'country', 'phone'];

export const isValidBasketAddress = (address: { [key: string]: string | undefined }) => {
    const isFieldRequierd = (key: string) => addressRequiredFields.includes(key);
    const empty = (key: string) => address[key] === '' || address[key] === null;
    const valid = Object.keys(address).every(key => (isFieldRequierd(key) ? !empty(key) : true));
    const allEmpty = Object.keys(address).every(empty);

    return valid && !allEmpty;
};

/**
 * Street name and number.
 * Allowed special characters: -'´`",.:;#&/()+@ °ºᵃª_ \
 * https://docs.klarna.com/klarna-payments/in-depth-knowledge/customer-data-requirements/#eu-region-sweden,-norway,-finland-and-denmark
 * @param {string} streetName
 * @returns {boolean}
 */
export const isValidStreetName = (streetName: string) => {
    const unallowedSpecialChars = unAllowedStreetNameCharsRegExp();
    const containsOnlyLatinChars = isValidExtendedLatinRegExp();
    return !unallowedSpecialChars.test(streetName) && containsOnlyLatinChars.test(streetName);
};

// @todo: Change back to "Can not be only numbers, must be more than 1 character.".
/**
 * Customers given name in UTF-8 encoding.
 * Can not contain any numbers, must be more than 1 character.
 * Allowed special characters: -'’.
 * https://docs.klarna.com/klarna-payments/in-depth-knowledge/customer-data-requirements/#eu-region-sweden,-norway,-finland-and-denmark
 * @param {string} name
 * @returns {boolean}
 */
export const isValidName = (name: string) => {
    const unallowedSpecialChars = isValidNameRegExp();
    const containsNumbers = containNumbersRegExp();
    const containsOnlyLatinChars = isValidExtendedLatinRegExp();
    return (
        !unallowedSpecialChars.test(name) &&
        !containsNumbers.test(name) &&
        containsOnlyLatinChars.test(name) &&
        name.length > 1
    );
};

export const isValidEmail = (email: string) => {
    const regexNumbersOnlyBeforeAt = numbersOnlyBeforeAtRegExp();
    const regexNumbersOnlyAfterAt = numbersOnlyAfterAtRegExp();
    const isEmailOnlyNumbersBeforeAt = regexNumbersOnlyBeforeAt.test(email);
    const isEmailOnlyNumbersAfterAt = regexNumbersOnlyAfterAt.test(email);
    const regex = isValidEmailRegExp();

    const isEmailValid = regex.test(email);
    return !isEmailOnlyNumbersBeforeAt && !isEmailOnlyNumbersAfterAt && isEmailValid;
};

/**
 * Test zipcode against correct format of that country
 * @param {string} zip
 * @param {string} countryCode
 * @returns
 */

export const isValidZipCode = (zip: string, countryCode: string | null) => {
    const regex = isValidZipCodeRegExp(countryCode);
    return regex.test(zip);
};

export const objHasValues = obj => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');
