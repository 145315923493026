import { useState } from 'react';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import withHeightAuto from '@activebrands/core-web/components/withHeightAuto';
import media from '@activebrands/core-web/config/media';
import LipScoreServiceSmallReviewsBadge from '@activebrands/core-web/libs/lipscore/serviceReviewWidgets/LipScoreServiceSmallReviewsBadge';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import ThemeButton from 'components/buttons/ThemeButton';
import LogotypeIcon from 'components/icons/LogotypeIcon';
import StateIcon from 'components/icons/StateIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    width: '100%',
    padding: '48px 0 32px',
    color: 'var(--color-text-footer-primary)',
    backgroundColor: 'var(--color-bg-footer-primary)',
    borderTop: '1px solid var(--color-border-footer-inverted)',

    [media.min['tablet.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '8px',
        padding: '48px var(--margin-content) 32px',
    },

    [media.min['desktop.sm']]: {
        padding: '48px var(--margin-content)',
    },
});

const LogoAndReviewsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    padding: '0 var(--margin-content) 48px',
    position: 'relative',
    width: '100%',

    [media.min['tablet.sm']]: {
        alignItems: 'flex-start',
        gridColumn: '1 / span 3',
        gridRow: 1,
        padding: '0 0 48px 0',
    },
});

const AccordionWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',

    [media.min['tablet.sm']]: {
        gridColumn: '7 / span 6',
        gridRow: 3,
        flexDirection: 'row',
        marginBottom: '0',
    },
});

const FeaturedWrapper = styled('div', {
    padding: '0 var(--margin-content) 32px',

    [media.min['tablet.sm']]: {
        padding: '0',
        gridColumn: '7 / span 6',
        gridRow: '1 / span 2',
    },
});

const FeaturedLinksWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px 4px',

    [media.min['tablet.sm']]: {
        gap: '8px',
    },
});

const MenuItem = styled('div', {
    borderBottom: '1px solid var(--color-border-footer-secondary)',

    ':first-child': {
        borderTop: ['1px solid var(--color-border-footer-secondary)', null, null, '0'],
    },

    [media.min['tablet.sm']]: {
        width: '33.33%',
        borderBottom: 0,
        wordWrap: 'break-word',
    },
});

const MenuHeaderMobile = styled('button', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '56px',
    width: '100%',
    padding: '8px var(--margin-content)',
    lineHeight: '100%',

    [media.min['tablet.sm']]: {
        display: 'none',
    },
});

const MenuHeader = styled('div', {
    display: 'none',

    [media.min['tablet.sm']]: {
        visibility: 'visible',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'unset',
        height: 'unset',
        marginBottom: '16px',
        lineHeight: '100%',
        textTransform: 'uppercase',
    },
});

const Content = withHeightAuto(
    styled('div', {
        overflow: 'hidden',
        transitionDuration: '500ms',
        transitionProperty: 'height',
        transitionTimingFunction: 'var(--ease)',

        [media.min['tablet.sm']]: {
            height: 'auto !important',
            overflow: 'visible',
            transition: 'none',
            color: 'var(--color-text)',
        },
    })
);

const InnerContent = styled('nav', {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '20px var(--margin-content)',

    [media.min['tablet.sm']]: {
        gap: '16px',
        padding: '0',
    },
});

const StyledLink = styled(Link, {
    display: 'block',
    color: 'var(--color-text-button-default)',
    ...responsiveTextStyles(['Primary/16_100_-05', null, 'Primary/14_100_-3']),
    padding: '12px 0',

    [media.min['tablet.sm']]: {
        padding: '0',
    },
});

const InformationTextWrapper = styled('div', {
    gridColumn: '1 / span 4',
    gridRow: '2 / span 3',
    padding: '0 var(--margin-content)',

    [media.min['tablet.sm']]: {
        padding: '0',
    },
});

const FooterMenuInfo = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    ...textStyles['Primary/14_140_-2'],

    [media.min['tablet.sm']]: {
        paddingBottom: '32px',
    },
});

const FooterMenu = ({ items, informationHeading, featuredLinks, featuredLinksLabel, informationParagraphs }) => {
    const [css] = useStyletron();

    const headingSettings = transformHeading({ type: informationHeading?.[0]?.type, defaultType: 'h3' });

    return (
        <Wrapper>
            <LogoAndReviewsWrapper>
                <LogotypeIcon color="var(--fill-logo-footer)" width="145px" />
                <div className={css({ marginTop: ['24px', null, null, '32px'], width: '100%' })}>
                    <LipScoreServiceSmallReviewsBadge type="long" />
                </div>
            </LogoAndReviewsWrapper>
            <FeaturedWrapper>
                {featuredLinksLabel && (
                    <Heading $style={{ marginBottom: '16px' }} as="h3" fontKeys="Primary/16_100_-05_500">
                        {featuredLinksLabel}
                    </Heading>
                )}
                <FeaturedLinksWrapper>
                    {featuredLinks?.map((link, linkIndex) => (
                        <ThemeButton
                            as={Link}
                            theme="outlined"
                            key={`${link.label}-${linkIndex}`}
                            to={link?.link?.url}
                            fontKeys="Primary/16_100_-05"
                        >
                            {link.label}
                        </ThemeButton>
                    ))}
                </FeaturedLinksWrapper>
            </FeaturedWrapper>
            <AccordionWrapper $style={{ gap: [0, null, '8px', null, 0] }}>
                {items.map((footerItem, footerItemIndex) => {
                    const [open, setOpen] = useState(false);
                    const [iconState, setIconState] = useState('plus');
                    return (
                        <MenuItem key={`${footerItem.label}-${footerItemIndex}`}>
                            <MenuHeaderMobile
                                type="button"
                                onClick={() => {
                                    setOpen(!open);
                                    setIconState(iconState === 'minus' ? 'plus' : 'minus');
                                }}
                            >
                                <Paragraph fontKeys="Primary/16_100_-05_500">{footerItem.label}</Paragraph>
                                <StateIcon
                                    $style={{ display: [null, null, null, 'none'] }}
                                    size="16px"
                                    state={iconState}
                                />
                            </MenuHeaderMobile>
                            <MenuHeader>
                                <Paragraph fontKeys="Primary/16_100_-05_500">{footerItem.label}</Paragraph>
                            </MenuHeader>
                            <Content height={open ? 'auto' : 0}>
                                <InnerContent>
                                    {footerItem?.menuItems?.map((footerMenuItem, footerMenuItemIndex) => (
                                        <StyledLink
                                            isVolatile
                                            key={`${footerMenuItem.label}-${footerItemIndex}-${footerMenuItemIndex}`}
                                            rel={footerMenuItem.follow === 'no_follow' ? 'nofollow' : undefined}
                                            to={footerMenuItem.link?.url}
                                        >
                                            {footerMenuItem.label}
                                        </StyledLink>
                                    ))}
                                </InnerContent>
                            </Content>
                        </MenuItem>
                    );
                })}
            </AccordionWrapper>
            <InformationTextWrapper>
                {informationHeading?.length > 0 && (
                    <Heading
                        $style={{ marginBottom: ['8px', null, null, '16px'] }}
                        as={headingSettings.type}
                        fontKeys={['Primary/16_100_-05_500', null, null, 'Primary/24_120_-1']}
                    >
                        {informationHeading[0].text}
                    </Heading>
                )}
                {informationParagraphs?.length > 0 && (
                    <FooterMenuInfo>
                        {informationParagraphs.map((paragraph, paragraphIndex) => (
                            <RichText key={`${paragraph._uid}-${paragraphIndex}`} data={paragraph.richText} />
                        ))}
                    </FooterMenuInfo>
                )}
            </InformationTextWrapper>
        </Wrapper>
    );
};

FooterMenu.propTypes = {
    featuredLinks: PropTypes.array,
    featuredLinksLabel: PropTypes.string,
    informationHeading: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
        })
    ),
    informationParagraphs: PropTypes.array,
    items: PropTypes.array,
};

export default FooterMenu;
