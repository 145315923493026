import { useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import Price from '@activebrands/core-web/components/Price';
import SpinnerIcon from '@activebrands/core-web/components/icons/SpinnerIcon';
import { styled } from '@activebrands/core-web/libs/styletron';
import { ecommerceEnabled } from '@activebrands/core-web/utils/constants';
import getCampaignPrice from '@activebrands/core-web/utils/product/get-campaign-price';
import Badges from 'components/Badges';
import ColorSwatches from 'components/product/ColorSwatches';
import AddToBasketCardEnd from './AddToBasketCardEnd';
import BasketCardEnd from './BasketCardEnd';
import ColorSwatchCardEnd from './ColorSwatchCardEnd';
import OrderCardEnd from './OrderCardEnd';
import QuickshopMiniDesktop from './QuickshopMiniDesktop';

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    color: 'var(--color-text-product-card-mini)',
});

const InnerWrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '4px',
    borderRadius: '4px',
    backgroundColor: 'var(--color-bg)',
    transition: 'opacity var(--transition-slow)',
});

export const ImageAndInfoWrapper = styled(Link, {
    display: 'flex',
    flex: 1,
});

export const ImageWrapper = styled('div', {
    display: 'flex',
    position: 'relative',
    flexShrink: '0',
});

export const ProductInfo = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px',
    gap: '8px',
});

const ProductInfoTop = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
});

export const Name = styled('h3', {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineHeight: 'unset',
    ...textStyles['Primary/14_100_-2_500'],
});

export const Text = styled('p', {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'var(--color-text-subtle-product-card-mini)',
    lineHeight: 'unset',
    ...textStyles['Primary/12_100_-1'],
});

export const Remove = styled('button', {
    textAlign: 'right',
    paddingBottom: '4px',
});

export const Loading = styled(SpinnerIcon, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const cardEnds = {
    addToBasket: AddToBasketCardEnd,
    basket: BasketCardEnd,
    colorSwatch: ColorSwatchCardEnd,
    wishlist: AddToBasketCardEnd,
    order: OrderCardEnd,
};

const ProductCardMini = ({
    $style = {},
    badges = [],
    color,
    extraInfo,
    id,
    imageLoading = 'lazy',
    innerWrapperStyle = {},
    isTeamStoreProduct,
    line,
    media = {},
    name,
    onClick = () => null,
    price: defaultPrice,
    productInfoStyle = {},
    quantity,
    relatedVariations = [],
    showFavorite = true,
    showImage = true,
    showPrice = true,
    size,
    to,
    type = 'addToBasket',
    uri,
    variantName,
    variations = [],
}) => {
    const [isQuickshopOpen, setIsQuickshopOpen] = useState(false);
    const [buttonState, setButtonState] = useState('default');
    const [isLoading, setIsLoading] = useState(false);

    // If there is a active campaign, use that price instead
    const campaignPricelist = useSelector(state => state.campaignSite.campaignPricelist);
    const campaignPrice = getCampaignPrice(id, campaignPricelist);
    const price = campaignPrice || defaultPrice;

    const CardEnd = cardEnds[type];
    const hasRelatedVariations = relatedVariations.length > 0;
    const addedToBasketData = {
        color,
        media,
        name,
        price,
        uri,
    };

    const isTypeValueProduct = media ? media.typeValue === 'Product' : false;

    return (
        <Wrapper $style={$style}>
            {isLoading && <Loading />}
            <InnerWrapper $style={{ flexDirection: 'row', opacity: isLoading ? 0.5 : 1, ...innerWrapperStyle }}>
                <ImageAndInfoWrapper to={uri || to} onClick={onClick}>
                    {showImage && media && (
                        <ImageWrapper>
                            <Image
                                $style={{
                                    height: '110px',
                                    width: '88px',
                                    objectFit: isTypeValueProduct ? 'contain' : 'cover',
                                    backgroundColor: 'var(--color-bg-image)',
                                }}
                                alt={name}
                                draggable={false}
                                loading={imageLoading}
                                src={{ url: media.url, width: [200] }}
                            />
                        </ImageWrapper>
                    )}
                    <ProductInfo $style={{ flexShrink: 1, minWidth: '0', maxWidth: '130px', ...productInfoStyle }}>
                        <ProductInfoTop>
                            {!!badges.length && <Badges list={badges} />}
                            {name && <Name>{name}</Name>}
                            {color && type !== 'colorSwatch' && !isTeamStoreProduct && (
                                <Text title={color}>
                                    {size ? `${fm(color)}, ${fm(size).toUpperCase()}` : fm(color)}
                                </Text>
                            )}
                            {extraInfo && <Text $style={{ marginTop: '4px' }}>{extraInfo}</Text>}
                            {isTeamStoreProduct && (
                                <Text title={variantName}>{`${variantName}, ${fm(size).toUpperCase()}`}</Text>
                            )}
                        </ProductInfoTop>
                        {showPrice && price?.salePriceWithCurrency && (
                            <Price
                                activePrice={price.salePriceWithCurrency}
                                fontStyling={textStyles['Primary/14_100_-2_500']}
                                originalPrice={price.priceWithCurrency}
                            />
                        )}
                        {hasRelatedVariations && type === 'colorSwatch' && (
                            <ColorSwatches
                                currentVariationId={id}
                                relatedVariations={relatedVariations}
                                textStyles="Misc/10_100_500_UC"
                            />
                        )}
                    </ProductInfo>
                </ImageAndInfoWrapper>
                <CardEnd
                    addedToBasketData={addedToBasketData}
                    color={color}
                    id={id}
                    image={media.url}
                    isLoading={isLoading}
                    isQuickshopOpen={isQuickshopOpen}
                    line={line}
                    name={name}
                    priceWithCurrency={price?.priceWithCurrency}
                    quantity={quantity}
                    relatedVariations={relatedVariations}
                    salePriceWithCurrency={price?.salePriceWithCurrency}
                    setIsLoading={setIsLoading}
                    setIsQuickshopOpen={setIsQuickshopOpen}
                    showFavorite={showFavorite}
                    size={size}
                    uri={uri}
                    variations={variations}
                />
            </InnerWrapper>
            {ecommerceEnabled && isQuickshopOpen && (
                <QuickshopMiniDesktop
                    addedToBasketData={addedToBasketData}
                    buttonState={buttonState}
                    color={color}
                    name={name}
                    setButtonState={setButtonState}
                    setIsLoading={setIsLoading}
                    setIsQuickshopOpen={setIsQuickshopOpen}
                    uri={uri}
                    variations={variations}
                />
            )}
        </Wrapper>
    );
};

ProductCardMini.propTypes = {
    $style: PropTypes.object,
    badges: PropTypes.array,
    color: PropTypes.string,
    extraInfo: PropTypes.string,
    id: PropTypes.string,
    imageLoading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    innerWrapperStyle: PropTypes.object,
    isTeamStoreProduct: PropTypes.bool,
    line: PropTypes.string,
    media: PropTypes.object,
    name: PropTypes.string,
    onClick: PropTypes.func,
    price: PropTypes.object,
    productInfoStyle: PropTypes.object,
    quantity: PropTypes.number,
    relatedVariations: PropTypes.array,
    showFavorite: PropTypes.bool,
    showImage: PropTypes.bool,
    showPrice: PropTypes.bool,
    size: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(cardEnds)).isRequired,
    uri: PropTypes.string,
    variantName: PropTypes.string,
    variations: PropTypes.array,
};

export default ProductCardMini;
